import statusPageTransformer from '@/services/transformers/statusPageTransformer.js'
import dayjs from 'dayjs'

const baseURL = 'status-pages'

export default {
  /**
   * Get status pages.
   *
   * @param params
   * @returns Promise
   */
  get (params = {}) {
    return axios.get(`${baseURL}/`, {
      params
    }).then(response => response.data.data.map(statusPageTransformer))
  },

  /**
   * Get specific status page by id.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  find (id, params = {}) {
    return axios.get(`${baseURL}/${id}`).then(response => statusPageTransformer(response.data.data))
  },

  /**
   * Get public status page by slug.
   *
   * @param slug
   * @param password
   * @returns Promise
   */
  async findBySlugOrDomain (slug, password) {
    const response = await axios.post(`public/status/${slug}`, { password },
      { headers: { Authorization: '', Accept: 'application/json' } })
    return statusPageTransformer(response.data.data)
  },

  /**
   * Store new status page.
   *
   * @param params
   * @returns Promise
   */
  store (params) {
    return axios.post(baseURL, params).then(response => statusPageTransformer(response.data.data))
  },

  async saveStyles (data, id) {
    const response = await axios.post(`status-pages/${id}/style`, data)
    return response.data
  },

  async storeOrUpdateLanguage (data, id) {
    try {
      const response = await axios.post(`status-pages/${id}/language`, data)
      return response.data
    } catch {
      return false
    }
  },

  /**
   * Store logo for status page.
   *
   * @param id
   * @param file
   * @returns Promise
   */
  async storeLogo (id, file) {
    let formData = new FormData()
    formData.append('logo', file)

    const response = await axios.post(`${baseURL}/${id}/logo`, formData)

    return statusPageTransformer(response.data.data)
  },

  /**
   * Delete status page logo.
   *
   * @param id
   * @returns Promise
   */
  async deleteLogo (id) {
    const response = await axios.delete(`${baseURL}/${id}/logo`)

    return response.data
  },

  async storeStyleLogo (id, file) {
    let formData = new FormData()
    formData.append('logo', file)

    return axios.post(`${baseURL}/${id}/style/logo`, formData).catch(error => {
      console.error(error)
      return 'error'
    })
  },

  async deleteStyleLogo (id) {
    const response = await axios.delete(`${baseURL}/${id}/style/logo`)

    return response.data
  },

  async storeStyleHeaderImage (id, file) {
    let formData = new FormData()
    formData.append('header_image', file)

    return axios.post(`${baseURL}/${id}/style/header-image`, formData).catch(error => {
      console.error(error)
      return 'error'
    })
  },

  async deleteStyleHeaderImage (id) {
    const response = await axios.delete(`${baseURL}/${id}/style/header-image`)

    return response.data
  },

  async storeStyleFaviconImage (id, file) {
    let formData = new FormData()
    formData.append('favicon_image', file)

    return axios.post(`${baseURL}/${id}/style/favicon-image`, formData).catch(error => {
      console.error(error)
      return 'error'
    })
  },

  async deleteStyleFaviconImage (id) {
    const response = await axios.delete(`${baseURL}/${id}/style/favicon-image`)

    return response.data
  },
  /**
   * Update status page.
   *
   * @param id
   * @param params
   * @returns Promise
   */
  update (id, params) {
    return axios.put(`${baseURL}/${id}`, params).then(response => response.data.data)
  },

  /**
   * Delete status page.
   *
   * @param id
   * @returns Promise
   */
  delete (id) {
    return axios.delete(`${baseURL}/${id}`).then(response => response.data)
  },

  /**
   * Get snapshots for status page monitors.
   *
   * @param slug
   * @returns Promise
   */
  async getSnapshots (slug) {
    // todo: find a way to refactor transformRequest issue

    const response = await axios.get(`public/status/${slug}/snapshots`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })

    let data = response.data

    data.forEach(item => {
      item.snapshots = item.snapshots.map(snapshot => {
        return {
          ...snapshot,
          date: dayjs.unix(snapshot.date).format('DD-MM-YYYY'),
          node: {}
        }
      })
    })

    return data
  },

  async getNodes () {
    const nodesResponse = await axios.get(`public/nodes`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })

    return nodesResponse.data
  },

  getIncidentsHistoryBySlug (slug) {
    return axios.get(`/public/status/${slug}/snapshots-history`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })
  },

  getMaintenancesHistoryBySlug (slug) {
    return axios.get(`/public/status/${slug}/maintenances-history`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })
  },

  getMaintenanceHistoryBySlug (slug) {
    return axios.get(`/public/status/${slug}/maintenance-history`, {
      transformRequest: [(data, headers) => {
        delete headers.Authorization
        return data
      }]
    })
  },

  arrangeStatusPages (data) {
    return axios.post(`${baseURL}/arrange-status-pages`, data)
  },

  subscribeToPage (subscribeData) {
    return axios.post(`public/status-page/${subscribeData.statusPageId}/send-verification-email`, subscribeData, { headers: { Authorization: '', Accept: 'application/json' } })
  },

  async verifyEmail (data) {
    try {
      const response = await axios.post('/public/status-page/verify-email', data,
        { headers: { Authorization: '', Accept: 'application/json' } })
      return response.data
    } catch {
      return false
    }
  },

  sendUrlEmail (data) {
    return axios.post('public/status-page/send-url-email', data, { headers: { Authorization: '', Accept: 'application/json' } })
  },

  getSubscriptionsByHash (hash) {
    return axios.post('public/status-page/get-subscriptions', { hash }, { headers: { Authorization: '', Accept: 'application/json' } })
  },

  unsubscribe (pageId, hash) {
    return axios.post('public/status-page/unsubscribe', { pageId, hash }, { headers: { Authorization: '', Accept: 'application/json' } })
  },

  checkHashValidity (hash) {
    return axios.post('public/status-page/check-hash', { hash }, { headers: { Authorization: '', Accept: 'application/json' } })
  },

  storeMaintenance (id, data) {
    return axios.post(`${baseURL}/${id}/maintenance`, data)
  },

  updateMaintenance (id, data) {
    return axios.put(`${baseURL}/maintenance/${id}`, data).then(
      response => response.data
    )
  },

  getStatusPageMaintenanceItems (id, params) {
    return axios.get(`${baseURL}/${id}/maintenance`, { params: params }).then(
      response => response.data
    )
  },

  deletePageMaintenanceItem (id) {
    return axios.delete(`${baseURL}/maintenance/${id}`)
  },

  getMaintenanceItem (maintenanceId) {
    return axios.get(`${baseURL}/maintenance/${maintenanceId}`).then(
      response => response.data
    )
  },

  getPasswordTranslation (slugOrDomain) {
    return axios.get(`public/status/${slugOrDomain}/password-language`, { headers: { Authorization: '', Accept: 'application/json' } }).then(
      response => response.data
    )
  }
}
